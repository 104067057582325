<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{typeCapitalize=='General' ? 'Tickets' : typeCapitalize+' tickets'}}  <span class="badge badge-square badge-primary -mt-5">{{ no_of_unread_ticket }}</span>
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{typeCapitalize=='General' ? 'Tickets' : typeCapitalize+' tickets'}}
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar" v-if="typeCapitalize=='Refund'">
                  <div class="d-flex">
                    <div class="mr-3">
                      <p><b>Pending: </b><span class="badge badge-primary">{{$store.getters.currentTotalPending}}</span></p>
                      <p><b>Total: </b><span class="badge badge-primary">{{$store.getters.currentTotalRefunded}}</span></p>
                    </div>
                    <div>
                      <p><b>Approved: </b><span class="badge badge-primary">{{$store.getters.currentTotalApproved}}</span></p>
                      <p><b>Declined: </b><span class="badge badge-primary">{{$store.getters.currentTotalRejected}}</span></p>
                    </div>
                  </div>
                </div>
                <div class="card-toolbar" v-if="typeCapitalize!='Refund'">
<!--                  <v-menu bottom left offset-y v-if="support_tickets.length > 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <i class="fas fa-list "></i>
                      </v-btn>
                    </template>

                    <v-list v-if="support_tickets.length > 0">
                      <v-list-item
                          link
                          v-if="support_tickets_ids.length > 0"
                          @click="markAsRead"
                      >
                        <v-list-item-title
                        >
                          Mark as read
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          link
                          v-if="support_tickets_ids.length > 0"
                          @click="markAsUnRead"
                      >
                        <v-list-item-title
                        >
                          Mark as unread
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          link
                          @click="markAllRead"
                      >
                        <v-list-item-title
                        >
                          Mark all as read
                        </v-list-item-title >
                      </v-list-item>
                    </v-list>
                  </v-menu>-->
                  <v-btn class="cancel-btn mr-2" text x-large
                         @click="gotToClosedTicket()"
                  >
                    View closed tickets
                  </v-btn>
                  <v-btn class="btn btn-primary font-weight-bolder font-size-sm"
                    style="color: #fff;"
                    @click="createSupportTicket()"
                  >
                    <i class="fa fa-plus"></i>
                    Add ticket
                  </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.date_from"
                          label="View from date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchSupportTicket()"
                          @input="search.date_from = $event !== null ? $event : ''"
                          :error="checkDateFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.date_from"
                        no-title
                        @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                  <!--                  <span v-if="checkDateFrom" class="text-danger">This information is required !!</span>-->
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.date_to"
                          label="View up to date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchSupportTicket()"
                          :error="checkDateTo"
                          @input="search.date_to = $event !== null ? $event : ''"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.date_to"
                        no-title
                        @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <!--                  <span v-if="checkDateTo" class="text-danger">This information is required !!</span>-->
                </v-col>

<!--                <v-col cols="12" sm="6" md="2">-->
<!--                  <v-text-field-->
<!--                      label="Request"-->
<!--                      v-model="search.name"-->
<!--                      outlined-->
<!--                      dense-->
<!--                      @input="search.name = $event !== null ? $event : ''"-->
<!--                      clearable-->
<!--                      v-on:keyup.enter="searchSupportTicket()"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->

                    <v-col cols="12" sm="6" md="2">
                      <v-text-field
                          label="Search by reference"
                          v-model="search.reference"
                          outlined
                          dense
                          @input="search.reference = $event !== null ? $event : ''"
                          clearable
                          v-on:keyup.enter="searchSupportTicket()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                      <v-select
                          label="Type"
                          v-model="search.type"
                          :items="types"
                          item-text="name"
                          item-value="value"
                          @change="setType"
                          outlined
                          dense
                          @input="search.type = $event !== null ? $event : ''"
                          v-on:keyup.enter="searchSupportTicket()"
                          return-object
                          clearable
                      ></v-select>
                    </v-col>

<!--                <v-col cols="12" sm="6" md="2">-->
<!--                  <v-select-->
<!--                      label="Support Type"-->
<!--                      v-model="search.category_id"-->
<!--                      :items="categories"-->
<!--                      item-text="name"-->
<!--                      item-value="id"-->
<!--                      outlined-->
<!--                      dense-->
<!--                      @input="search.category_id = $event !== null ? $event : ''"-->
<!--                      v-on:keyup.enter="searchSupportTicket()"-->
<!--                      clearable-->
<!--                  ></v-select>-->
<!--                </v-col>-->

                <v-col cols="12" md="2" v-if="type!='refund'">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2" v-if="type=='refund'">
                  <v-select
                      :items="progress_status"
                      v-model="search.progress_status"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Search by email, name or phone"
                      v-model="search.user_info"
                      outlined
                      dense
                      @input="search.user_info = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Enrolment key"
                      v-model="search.enrol_key"
                      outlined
                      dense
                      @input="search.enrol_key = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                  <v-select
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      @input="search.score_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Admin activity type"
                      v-model="search.is_read"
                      :items="read_types"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @input="search.is_read = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Assigned to"
                      v-model="search.assignedTo"
                      outlined
                      dense
                      @input="search.assignedTo = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Assigned by"
                      v-model="search.assignedBy"
                      outlined
                      dense
                      @input="search.assignedBy = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                  <v-select
                      v-model="search.assigned_type"
                      :items="assignedTypes"
                      label="Access type"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.assigned_date"
                          label="Assigned date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.assigned_date"
                        outlined
                        dense
                        no-title
                        @input="menu2 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" :md="currentUser &&currentUser.access_type == 'score' ? 2 : 10">
                  <v-btn
                    @click="searchSupportTicket()"
                    class="btn btn-primary float-right"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <v-row>
              <v-col cols="9"></v-col>
              <v-col cols="3">
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                      outlined
                      :items="available_actions"
                      label="Select action"
                      item-text="text"
                      item-value="value"
                      v-model="bulk_action"
                      dense
                  >
                  </v-autocomplete>

                  <v-btn class="btn btn-primary ml-4" style="color: #fff" @click="performBulkAction" :loading="bulkActionLoading">
                    Go
                    <v-icon small elevation="2" outlined class="ml-2">fas fa-arrow-right</v-icon>&nbsp;
                  </v-btn>
                </div>
              </v-col>
            </v-row>

            <div class="table-responsive">

              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3"></th>
                  <th class="px-3">Reference</th>
                  <th class="px-3" style="max-width: 300px; white-space: pre-wrap;">Request</th>
                  <th class="px-3">Type</th>
                  <th class="px-3">Enroller</th>
                  <th class="px-3" style="max-width: 180px !important; white-space: pre-wrap;">Candidate</th>
<!--                  <th class="px-3" style="max-width: 200px !important; white-space: pre-wrap;">Responsible</th>-->
                  <th class="px-3">Status</th>
                  <th class="px-3" style="max-width: 180px !important; white-space: pre-wrap;">Added date</th>
                  <th class="px-3">Assigned to</th>
                  <th class="px-3" v-if="currentUser.access_type!='score'">SCORE</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody class="mt-5">
                <template>
                  <tr v-bind:style="{ 'background-color': !item.is_read_admin ? ' #d5e0ec !important' : '' }" v-for="(item, index) in support_tickets" :key="index">
                    <td class="ps-9">
                      <div class="d-flex align-items-center bg-checkbox">
                        <v-checkbox @change="getMark($event, item)" v-model="checkBox[index]" :ripple="false"></v-checkbox>
                      </div>
                    </td>
                    <td class="px-2">

                        <span class=" font-weight-bolder">{{ item.id }}</span>

                    </td>
                    <td class="px-2" style="max-width: 300px; white-space: pre-wrap;">
                      <a @click="viewSupportThread(item)" class="d-flex align-items-center">
                        {{item.category_name ? item.category_name : item.name }}
                      </a>
                    </td>
                    <td class="px-2" style="max-width: 300px; white-space: pre-wrap;">
                      <span class="badge badge-warning" v-if="item.type=='refund'">Refund</span>
                      <span class="badge badge-primary" v-else>{{ item.type }}</span>
                    </td>
                    <td class="px-3" style="white-space: pre-wrap;">
                      <a  @click="goToUser(item)"> {{item.user ? item.user : 'NA' }}</a>
                    </td>
                    <!--Action-->
                    <td class="px-3" style="max-width: 180px !important; white-space: pre-wrap;">
                      <a @click="goToCandidate(item.candidate_exam_key)">{{item.candidate ? `${item.candidate} \n ${item.candidate_exam_key}`: 'NA' }}</a>
                    </td>
                    <!--Responsible-->
<!--                    <td style="max-width: 200px !important; white-space: pre-wrap;" class="px-3">-->
<!--                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">-->
<!--                        <b class="mr-3" v-if="item.assignedTo">Assigned to: </b> {{item.assignedTo ? item.assignedTo : '' }}-->
<!--                      </span>-->
<!--                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">-->
<!--                        <b class="mr-3" v-if="item.assignedBy">Assigned by: </b> {{item.assignedBy ? item.assignedBy : '' }}-->
<!--                      </span>-->
<!--                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">-->
<!--                        <b class="mr-3" v-if="item.formatted_assigned_date">Assigned date: </b> {{item.formatted_assigned_date ? item.formatted_assigned_date : '' }}-->
<!--                      </span>-->
<!--                    </td>-->
                    <!--Status-->
                    <td class="px-3">
                      <div>
                        <span class="badge badge-warning" v-bind:class="{ 'badge-warning': item.ticket_status == 'In progress',
                        'badge-warning': item.ticket_status == 'Pending','badge-danger': item.ticket_status == 'Closed'}"
                       >
                          {{ item.ticket_status=='In progress' ? 'Pending' : item.ticket_status }}
                          </span>
                      </div>
                      <div class="mt-3" v-if="!item.is_refunded && !item.is_approved && item.is_rejected && item.type=='refund'">
                        <span class="badge badge-danger">Declined</span>
                      </div>
                      <div class="mt-3" v-if="!item.is_refunded && item.is_approved && !item.is_rejected && item.type=='refund'">
                        <span class="badge badge-primary">Approved</span>
                      </div>
                      <div class="mt-3" v-if="item.is_refunded && item.is_approved && item.type=='refund'">
                        <span class="badge badge-primary">Refunded</span>
                      </div>
                    </td>
                    <!--Added date-->
                    <td class="px-3" style="max-width: 180px !important; white-space: pre-wrap;">
                      <span  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{ item.formatted_created_at ? item.formatted_created_at : '--'}}
                      </span>
<!--                      {{// item.readable_created_at}}-->
                    </td>
                    <td>
                      {{item.assigned_type_text}}
                    </td>
                    <td v-if="currentUser.access_type!='score'">
                     {{item.score_purchase_prefix}}
                    </td>
                    <!--Action-->
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="assignedTo(item)" class="navi-link">
                                  <span class="navi-icon">
                                    <i class="fa fa-user"></i>

                                  </span>
                                <span class="navi-text">Reassign ticket</span>
                              </a>
                            </b-dropdown-text>
<!--                            <b-dropdown-text tag="div" class="navi-item" v-if="currentUser.access_type == 'federal' && item.assigned_type && item.assigned_by  && canAssignTicket(item.candidate_product_type,item.type)">-->
<!--                              <a @click="unAssignedTicket(item.id)" class="navi-link">-->
<!--                                  <span class="navi-icon">-->
<!--                                       <i class="fa fa-user-times"></i>-->
<!--                                  </span>-->
<!--                                <span class="navi-text">Unassign Ticket</span>-->
<!--                              </a>-->
<!--                            </b-dropdown-text>-->
<!--                            <b-dropdown-text tag="div" class="navi-item" v-if="item.type_text=='Refund' && item.assigned_to && item.assigned_by && !item.is_approved && !item.is_rejected">-->
<!--                            <b-dropdown-text tag="div" class="navi-item" v-if="item.type=='refund' && !item.is_approved && !item.is_rejected">
                              <a @click="manageRefund(item.id,item.is_refunded)" class="navi-link">
                                  <span class="navi-icon">
                                     <i class="fas fas fa-tasks"></i>

                                  </span>
                                <span class="navi-text">Approve/ Not approve</span>
                              </a>
                            </b-dropdown-text>-->
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="support_tickets.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No tickets available</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="support_tickets.length > 0"
                  class="pull-right mt-7"
                  @input="searchSupportTicket"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>

      <create-and-update ref="create-and-update" @refresh="getAllSupportTicket"></create-and-update>
      <assigned-to ref="assigned-to" @refresh="getAllSupportTicket"></assigned-to>
      <approved-refund ref="refund" @refresh="getAllSupportTicket"></approved-refund>
  </v-app>
</template>
<script>
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import CreateAndUpdate from "./CreateAndUpdate";
import ApprovedRefund from "./refund/ApprovedRefund";
import AssignedTo from "@/view/pages/view/support/support-ticket/assigned/AssignedTo";
import { SET_SUPPORT } from "@/core/services/store/support.module";
import ScoreService from "@/services/score/score/ScoreService";
import SupportCategoryService from "@/services/support/support-category/SupportCategoryService";

const supportTicket=new SupportTicketService();
const supportCategory = new SupportCategoryService();
const score = new ScoreService();
export default {
  components: {AssignedTo, CreateAndUpdate,ApprovedRefund},
  data() {
    return {
      categories:[],
      support_tickets:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      menu: false,
      menu1: false,
      menu2: false,
      isLoading: false,
      checkBox: [false],
      scores: [],
      support_tickets_ids: [],
      currentUser: {},
      type: 'all',
      search:{
        date_to: '',
        date_from: '',
        user_info: '',
        enrol_key: '',
        reference: '',
        name: '',
        type: '',
        category_id: '',
        is_read: '',
        score_id: '',
        assignedTo: '',
        is_closed: 0,
        is_active: '',
        progress_status:'pending',
      },
      assignedTypes: [
        {name: 'Federal', value: 'federal'},
        {name: 'Score', value: 'score'}
      ],
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      progress_status: [
        {name: 'Pending', value: 'pending'},
        {name: 'Approved', value: 'approved'},
        {name: 'Declined', value: 'rejected'},
        {name: 'Refunded', value: 'refunded'},
      ],
      read_types: [
        {name: 'Opened', value: '1'},
        {name: 'Not opened', value: '0'}
      ],
      types: [
        {name: 'General', value: 'general'},
        // {name: 'Extension', value: 'extension'},
        {name: 'Refund', value: 'refund'},
        // {name: 'Payment', value: 'payment'},
        // {name: 'Appeal', value: 'appeal'},
        // {name: 'Feedback', value: 'feedback'},
        // {name: 'Shipment', value: 'shipment'},
        {name: 'Other', value: 'other'},
      ],
      available_actions:[
        {text: 'Mark as read', value: 'mark_as_read'},
        {text: 'Mark as unread', value: 'mark_as_not_read'},
        {text: 'Mark all as read', value: 'mark_all_as_read'},
      ],
      bulkActionLoading: false,
      bulk_action:''
    }
  },
  mounted() {
    this.type = this.$route.params.type;
    if(this.type != 'all') {
      this.search.type = this.type;
    }
    this.getAllSupportTicket();
    this.getAllScore();
    this.getCurrentUser();
  },
  computed: {
    routeTab(){
      if(this.typeCapitalize=='Refund'){
        return {
          title: 'Refunds',
          icon: 'fa-solid fa-hand-holding-dollar'
        }
      }
      else{
        return {
          title: `Tickets`,
          icon: 'fa-solid fa-ticket'
        }
      }
    },
    no_of_unread_ticket() {
      if(this.type == 'all') {
        return this.$store.getters.currentSupport;
      } else if(this.type == 'general') {
        return this.$store.getters.currentGeneral;
      } else if(this.type == 'extension') {
        return this.$store.getters.currentExtension;
      } else if(this.type == 'refund') {
        return this.$store.getters.currentRefund;
      } else if(this.type == 'payment') {
        return this.$store.getters.currentPayment;
      } else if(this.type == 'appeal') {
        return this.$store.getters.currentAppeal;
      } else if(this.type == 'feedback') {
        return this.$store.getters.currentFeedback;
      } else if(this.type == 'shippment') {
        return this.$store.getters.currentShipment;
      } else {
        return this.$store.getters.currentOther;
      }
    },
    typeCapitalize() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    },
    checkDateFrom() {
      return this.search.date_to != '' && this.search.date_from == '';
    },
    checkDateTo() {
      return this.search.date_from != '' && this.search.date_to == '';
    }
  },
  methods:{

    setType() {
      let type = this.search.type;
      if(type) {
        this.type = type.value;
        this.search.type = type.value;
      }else {
        this.type = 'general';
      }
      this.getAllSupportTicket();
    },

    createSupportTicket(){
      this.$refs['create-and-update'].createSupportTicket();
    },

    gotToClosedTicket(){
      this.$router.push({
        name: 'closed-ticket'
      })
    },

    getMark(event, item) {
      if(event) {
        this.support_tickets_ids.push(item.id);
      }else {
        this.support_tickets_ids.splice(this.support_tickets_ids.indexOf(item.id), 1)
      }
    },

    getSupportCategories() {
      supportCategory.all().then(response => {
        this.categories = response.data.support_categories;
      }).catch(() => {})
    },

    goToUser(support_ticket) {
     if(support_ticket.user_id) {
       if(support_ticket.user_type == 'account_holder') {
         this.$router.push({
           name: 'accountholder-edit',
           params: {accountholderId: support_ticket.user_id}
         });
       } else if(support_ticket.user_type == 'examiner') {
         this.$router.push({
           name: 'examiner-edit',
           params: {examinerId: support_ticket.user_id}
         });
       } else if(support_ticket.user_type == 'supervisor') {
         this.$router.push({
           name: 'supervisor-edit',
           params: {supervisorId: support_ticket.user_id}
         });
       } else {
         this.$router.push({
           name: 'online-marker-edit',
           params: {onlineMarkerId: support_ticket.user_id}
         });
       }
     }
    },

    goToCandidate(examKey){
      if(examKey) {
        this.$router.push({
          name: 'candidate-summary',
          params: {examKey: examKey}
        });
      }
    },

    getAllSupportTicket(){
      this.loading =  true;
      supportTicket
          .paginate(this.search,this.page)
          .then(response => {
            this.support_tickets=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
            this.$store.dispatch(SET_SUPPORT);
          })
          .catch((err) => {this.loading =  false;});
    },

    searchSupportTicket(){
      this.page = 1;
      this.getAllSupportTicket();
    },

    viewSupportThread(ticket){
      if(ticket.type=='refund'){
        this.$router.push({
          name:'support-ticket-view',
          params:{supportTicketId: ticket.id}
        });
      }else{
        this.$router.push({
          name:'support-ticket-view',
          params:{supportTicketId: ticket.id}
        });
      }
      if(!ticket.is_read_admin) {
        this.support_tickets_ids.push(ticket.id);
        supportTicket.markAsReadTicket(this.support_tickets_ids).then(response => {
          this.support_tickets_ids = [];
          this.getAllSupportTicket();
        }).catch(() => {
          this.support_tickets_ids = [];
        });
      }
    },

    markAsRead() {
      if(this.support_tickets_ids.length > 0) {
        supportTicket.markAsReadTicket(this.support_tickets_ids).then(response =>{
          this.getAllSupportTicket();
          this.support_tickets_ids = [];
          this.checkBox = [false];
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }else {
        this.$snotify.warning("Please select tickets");
      }
    },

    markAsUnRead() {
      if(this.support_tickets_ids.length > 0) {
        supportTicket.markAsUnReadTicket(this.support_tickets_ids).then(response =>{
          this.getAllSupportTicket();
          this.support_tickets_ids = [];
          this.checkBox = [false];
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
      else {
        this.$snotify.warning("Please select tickets");
      }
    },

    getAllScore() {
        score.all().then(response => {
          this.scores = response.data.scores;
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
    },

    markAllRead() {
        supportTicket.markAllReadTicket().then(response => {
          this.getAllSupportTicket();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
    },

    assignedTo(supportTicket) {
      this.$refs['assigned-to'].assignedTo(supportTicket)
    },

    manageRefund(ticketId,refundStatus){
      this.$refs['refund'].openDialog(ticketId,refundStatus);
    },

    unAssignedTicket(supportTicketId) {
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            supportTicket.unassignedTicket(supportTicketId).then(response => {
              this.$snotify.success("Ticket Has Been UnAssigned");
              this.getAllSupportTicket();
            }).catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },

    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },

    performBulkAction()
    {

      if(this.bulk_action) {

        this.bulkActionLoading = true;
        if (this.bulk_action == 'mark_as_read') {
          this.markAsRead();
          this.bulkActionLoading = false;
        }

        if (this.bulk_action == 'mark_as_not_read') {
          this.markAsUnRead();
          this.bulkActionLoading = false;
        }

        if (this.bulk_action == 'mark_all_as_read') {
          this.markAllRead();
          this.bulkActionLoading = false;
        }

      }
      else
      {
        this.$snotify.error('Please select action');
      }
    },

    canAssignTicket(productType=null,type=null){
      if(type=='refund'){
        if(productType=='online_course'){
          return false;
        }else if(productType=='practical_exam'){
          return true;
        }else if(productType=='online_exam'){
          return false;
        }
      }else{
        return true;
      }
    }
  }
}
</script>