<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} ticket</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="6">
              <v-select
                  v-model="support_ticket.category_id"
                  :items="support_categories"
                  item-text="name"
                  item-value="id"
                  @change="setSupportType"
                  return-object
                  outlined
                  dense
                  :error="$v.support_ticket.category_id.$error"
                  menu-props="closeOnContentClick"
              >
                <template v-slot:label>
                  Category <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.support_ticket.category_id.$error">This information is required</span>
              <span class="text-danger" v-if="errors.category_id">{{ errors.category_id[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-select
                  v-model="support_ticket.type"
                  :items="types"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  :error="$v.support_ticket.type.$error"
                  :disabled="is_type"
                  menu-props="closeOnContentClick"
              >
                <template v-slot:label>
                  Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.support_ticket.type.$error">This information is required</span>
              <span class="text-danger" v-if="errors.type">{{ errors.type[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" v-if="selectedUser">
              <v-chip small close  @click:close="removeUser">{{selectedUser.full_name}}</v-chip>
            </v-col>

            <v-col cols="12" sm="12">
              <v-autocomplete
                  v-model="selectedUser"
                  :search-input.sync="userSearch"
                  :items="items"
                  :loading="isLoading"
                  item-text="display_text"
                  item-value="id"
                  @change="setUser"
                  :error="$v.support_ticket.user_id.$error"
                  return-object
                  hide-selected
                  outlined
                  dense
                  clearable
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>Search by candidate name or ticket reference number  </strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  Select enroller, examiner or supervisor <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.support_ticket.user_id.$error">This information is required</span>
              <span class="text-danger" v-if="errors.type">{{ errors.user_id[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" v-if="selectedCandidate">
              <v-chip small close  @click:close="removeCandidate">{{selectedCandidate.full_name}}</v-chip>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                outlined
                :error="$v.support_ticket.name.$error"
                dense
                v-model="support_ticket.name"
              >
                <template v-slot:label>
                  Ticket request title <span class="text-danger">*</span>
                </template>  
              </v-text-field>
              <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
              <span class="text-danger" v-if="$v.support_ticket.name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="12">
                <div class="text-body">
                  Ticket description in detail
                </div>
                <ckeditor
                  :error="$v.support_ticket.description.$error"
                  :config="editorConfig"
                  v-model="support_ticket.description" >
                </ckeditor>
                <span class="text-danger" v-if="errors.description" >{{errors.description[0]}}</span>
                <span class="text-danger" v-if="$v.support_ticket.description.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="12" v-if="support_ticket.user_id && support_ticket.type == 'refund'">
              <v-autocomplete
                  v-model="selectedCandidate"
                  :search-input.sync="candidateUserSearch"
                  :items="candidateItems"
                  :loading="isLoading"
                  @change="setCandidate"
                  item-text="display_text"
                  item-value="id"
                  :error="$v.support_ticket.candidate_id.$error"
                  return-object
                  hide-selected
                  outlined
                  dense
                  clearable
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>Search by candidate name or ticket reference number </strong>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  Candidate User <span class="text-danger">*</span>
                </template>
              </v-autocomplete>
              <span class="text-danger" v-if="$v.support_ticket.user_id.$error">This information is required</span>
              <span class="text-danger" v-if="errors.type">{{ errors.user_id[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              Status <span class="text-danger">*</span>
              <v-switch
                  v-model="support_ticket.is_active"
                  :label="support_ticket.is_active?'Active':'Inactive'"
                  color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="12" md="4">
              No response needed from enroller <span class="text-danger">*</span>
              <v-switch
                  v-model="support_ticket.user_reply_not_required"
                  :label="support_ticket.user_reply_not_required?'Required':'Not required'"
                  color="primary"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="12" md="4">
             Request approval <span class="text-danger">*</span>
              <v-switch
                  v-model="support_ticket.is_approved"
                  :label="support_ticket.is_approved?'Approved':'Not approved'"
                  color="primary"
              ></v-switch>
            </v-col>

          </v-row>

        </v-container>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import SupportCategoryService from "@/services/support/support-category/SupportCategoryService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import CandidateService from "@/services/candidate/CandidateService";

const user = new AccountholderService();
const supportTicket = new SupportTicketService();
const supportCategory = new SupportCategoryService();
const candidate = new CandidateService();

export default {
  name: "CreateAndUpdate",
  validations: {
    support_ticket: {
      name: {required},
      category_id: {required},
      description: {required},
      user_reply_not_required: {required},
      type: {required},
      is_active: {required},
      user_id: {required},
      candidate_id: {required: requiredIf(function () {
        return this.support_ticket.type == 'refund';
      })},
      cart_id: {},
      is_approved: {required},
      approved_by: {},
    }
  },
  data() {
    return {
      title: "",
      edit: false,
      errors: [],
      dialog: false,
      loading: false,
      isLoading: false,
      is_type: false,
      selectedUser: null,
      selectedCandidate: null,
      userSearch: null,
      candidateUserSearch: null,
      users: [],
      candidates: [],
      support_categories: [],
      support_ticket: {
        name: '',
        category_id: null,
        description: '',
        type: '',
        user_reply_not_required: 0,
        user_id: null,  
        candidate_id: null,
        cart_id: null,
        is_approved: 0,
        approved_by: null,
        approved_date: '',
        approved_remarks: '',
        is_refunded: 0,
        refunded_by: null,
        refund_date: '',
        refund_remarks: '',
        refund_amount: null,
        refund_percentage: null,
        is_closed: 0,
        closed_by: null,
        closing_date: '',
        closing_remarks: '',
        is_read_admin: 0,
        is_read_user: 0,
        score_id: null,
        is_active: 1,
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      types: [
        {name: 'General', id: 'general'},
        // {name: 'Extension', id: 'extension'},
        {name: 'Refund', id: 'refund'},
        // {name: 'Payment', id: 'payment'},
        // {name: 'Appeal', id: 'appeal'},
        // {name: 'Feedback', id: 'feedback'},
        // {name: 'Shipment', id: 'shipment'},
        {name: 'Other', id: 'other'},
      ],
    }
  },
  computed: {
    items() {
      return this.users.map((user) => {
        const display_text = user.first_name + ' '+ user.last_name+ ' || ' + user.email+' || '+ user.unique_identifier;
        return Object.assign({}, user, { display_text });
      });
    },
    candidateItems() {
      return this.candidates.map((user) => {
        const display_text = user.first_name+' '+ user.last_name+' || '+ user.exam_key;
        return Object.assign({}, user, { display_text });
      });
    }
  },
  watch: {
    userSearch (val) {
      if (this.isLoading) return;
      this.isLoading = true;
      if(val != null) {
        user
            .getAllByUserType(val)
            .then((response) => {
              this.users =response.data.accountholders
            })
            .catch(err => {

            })
            .finally(() => {
              this.isLoading = false
            })
      }
      this.isLoading = false
    },
    candidateUserSearch(val) {
      if (this.isLoading) return;
      this.isLoading = true;
      if(val != null) {
        candidate
            .getAllCandidateByOwner(this.support_ticket.user_id, val)
            .then((response) => {
              this.candidates =response.data.candidates
            })
            .catch(err => {

            })
            .finally(() => {
              this.isLoading = false
            })
      }
      this.isLoading = false
    }
  },
  methods: {
    setSupportType() {
      if(this.support_ticket.category_id) {
        let category = this.support_ticket.category_id;
        this.support_ticket.category_id = category.id;
        this.support_ticket.type = category.type;
        this.support_ticket.name = category.name;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createSupportTicket() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
      this.support_ticket.type = this.$route.params.type;
      if(this.support_ticket.type != 'all') {
        this.is_type = true;
      }
    },
    editSupportTicket(item) {
      this.openDialog();
      this.edit = true;
      this.support_ticket = item;
      this.title = "Edit";
    },
    setUser() {
      if(this.selectedUser != null) {
        this.support_ticket.user_id = this.selectedUser.id;
        this.support_ticket.score_id = this.selectedUser.current_score_id;
      } else {
        this.support_ticket.user_id = '';
      }
    },
    setCandidate() {
      if(this.selectedCandidate != null) {
        this.support_ticket.candidate_id = this.selectedCandidate.id;
      } else {
        this.support_ticket.candidate_id = '';
      }
    },
    removeUser() {
      this.support_ticket.user_id = '';
      this.selectedUser = null;
    },
    removeCandidate() {
      this.support_ticket.candidate_id = '';
      this.selectedCandidate = null;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      this.loading = true;
      supportTicket
      .create(this.support_ticket)
      .then(response => {
        this.$snotify.success("Ticket added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.response.data.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function () {
      this.loading = true;
      supportTicket
      .update(this.support_ticket.id, this.support_ticket)
      .then(response => {
        this.$snotify.success("Ticket updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.response.data.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    resetForm() {
      this.support_ticket = {
        name: '',
        category_id: null,
        user_reply_not_required: 0,
        user_id: null,
        candidate_id: null,
        cart_id: null,
        is_approved: 0,
        approved_by: null,
        approved_date: '',
        approved_remarks: '',
        is_refunded: 0,
        refunded_by: null,
        refund_date: '',
        refund_remarks: '',
        refund_amount: null,
        refund_percentage: null,
        is_closed: 0,
        closed_by: null,
        closing_date: '',
        closing_remarks: '',
        is_read_admin: 0,
        is_read_user: 0,
        score_id: null,
        is_active: 1,
      };
      this.$v.$reset();
      this.errors=[];
    },
    getSupportCategories(){
      supportCategory
      .all()
      .then(response => {
        this.support_categories = response.data.support_categories;
      })
      .catch(err => {})
    }
  },
  mounted(){
    this.getSupportCategories();
  }
}
</script>
